<template>
  <div class="block-content-column">
    <!-- <div v-if="branch" class="block-item condition">
      <div class="block-content">
        <span class="condition-status" :class="{ none: !branch.conditions }">
          {{ branch.name }}
        </span>
        <NewActionBlock :index="0" :level="level" @selected="addBlock" />
      </div>
    </div> -->
    <ConditionBranch v-if="branch" :branch="branch">
      <NewActionBlock :index="0" :level="level" @selected="addBlock" />
    </ConditionBranch>

    <div v-for="(block, index) in filteredBlocks" :key="`block-${index}`" class="block-item">
      <div class="block-content">
        <div class="block-controls">
          <span class="btn-action" title="Delete" @click="deleteBlock(index, block)">
            <i class="uil uil-trash-alt"></i>
          </span>
        </div>
        <component :is="block.action" :block="block" :blocks="blocks" :automation="automation" />
        <NewActionBlock :index="index + 1" :level="level" @selected="addBlock" />
      </div>
    </div>
    <template v-if="lastBlock && lastBlock.action === 'Condition'">
      <div class="condition-lines">
        <svg preserveAspectRatio="none" viewBox="0 0 800 800">
          <path v-for="(item, index) in lastBlock.branches" :key="`line-${level}-${index + 1}`"
                :d="`M 400 180 C 400 400 ${((index + 1) / lastBlock.branches.length * 800 - 800 / 2 / lastBlock.branches.length)} 400 ${((index + 1) / lastBlock.branches.length * 800 - 800 / 2 / lastBlock.branches.length)} 800`"
                pointer-events="visibleStroke" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="#9298ad"
                stroke-width="1"></path>
        </svg>
      </div>
      <div class="child-column">
        <list-block v-for="(item, subIndex) in lastBlock.branches" :key="`branch-${level}-${subIndex + 1}`"
                    :blocks="blocks" :level="`${level}-${subIndex + 1}`" :automation="automation" :deep="deep + 1"
                    :branch="item" />
      </div>
    </template>

    <b-modal v-model="isShowDeleteModal" title="Delete Block">
      <p v-if="selectedBlock"
      >Are you sure you want to delete block <strong>"{{ selectedBlock.name }}"</strong> ?</p
      >
      <template v-slot:modal-footer>
        <b-button variant="light" @click="isShowDeleteModal = false"
        >Cancel</b-button
        >
        <b-button variant="danger" @click="handleDeleteBlock">Delete</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import NewActionBlock from './NewActionBlock'
import Wait from './Wait'
import Notification from './Notification'
import SendEmail from './SendEmail'
import ConditionBranch from "./ConditionBranch.vue";

export default {
  name: 'ListBlock',

  components: {
    ConditionBranch,
    NewActionBlock,
    Wait,
    Notification,
    SendEmail,
  },

  props: {
    automation: {
      type: Object,
      default: null,
    },

    blocks: {
      type: Array,
      default: () => {
        return []
      }
    },

    level: {
      type: String,
      default: "1"
    },

    isShowYes: {
      type: Boolean,
      default: false,
    },

    isShowNo: {
      type: Boolean,
      default: false,
    },

    deep: {
      type: Number,
      default: 0,
    },

    branch: {
      type: Object,
      default: null,
    }
  },

  data() {
    return {
      selectedBlockIndex: null,
      isShowDeleteModal: false,
      selectedBlock: null,
    }
  },

  computed: {
    user() {
      return this.$store.getters['auth/user']
    },

    filteredBlocks() {
      return this.blocks.filter(b => !b.level || b.level === this.level)
    },

    lastBlock() {
      return this.blocks.filter(b => !b.level || b.level === this.level).pop()
    },

    customFields() {
      return this.$store.getters["auth/customFields"];
    },
  },

  methods: {
    addBlock(args) {
      let newBlock = JSON.parse(JSON.stringify(args.action))
      newBlock.isNewBlock = true
      newBlock.level = args.level

      let levelBlocks = this.automation.blocks.filter(b => b.level === args.level)

      if(args.action.action === 'CollectContactData') {
        this.customFields.forEach(function(item) {
          let format = item.type === 2 ? 'mm/dd' : ''
          newBlock.collectFields.push({name: item.id, label: item.name, type: item.type, format: format, enable: false, message: `Please reply with your ${ item.name}`});
        });
      }

      if (args.index === -1) {
        this.automation.blocks = [newBlock, ...this.automation.blocks]
      } else {
        if (args.index === 0) {
          this.automation.blocks = [
            newBlock, ...levelBlocks,
            ...this.automation.blocks.filter(b => b.level !== args.level)
          ]
        } else {
          this.automation.blocks = [
            ...(levelBlocks.slice(0, args.index)), newBlock, ...levelBlocks.slice(args.index, levelBlocks.length),
            ...this.automation.blocks.filter(b => b.level !== args.level)
          ]
        }
      }

      if (args.action.action === 'Condition') {
        for(let i = args.index + 1; i < this.automation.blocks.length; i++) {
          if (this.automation.blocks[i].level.match(`^${args.level}`)) {
            this.automation.blocks[i].level = `${args.level}-1${this.automation.blocks[i].level.replace(new RegExp(`^${args.level}`), '')}`
          }
        }
      }
    },

    deleteBlock(index, block) {
      this.isShowDeleteModal= true
      this.selectedBlock = block
      this.selectedBlockIndex = index
    },

    handleDeleteBlock() {
      this.isShowDeleteModal= false

      let levelBlocks = this.automation.blocks.filter(b => b.level === this.selectedBlock.level)
      levelBlocks.splice(this.selectedBlockIndex, 1)

      this.automation.blocks = [
        ...this.automation.blocks.filter(b => b.level !== this.selectedBlock.level),
        ...levelBlocks
      ]

      if (this.selectedBlock.action === 'Condition') {
        this.automation.blocks = this.automation.blocks.filter(b => !b.level.match(`^${this.selectedBlock.level}-`))
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.editor {
  margin: 50px auto 0;
  max-width: 600px;
}

.block-item.condition {
  padding-top: 0px;

  &:before {
    content: " ";
    width: 8px;
    height: 8px;
    position: absolute;
    background: #9298ad;
    border-radius: 50%;
    left: calc(50% - 4px);
    top: -4px;
    z-index: 2;
  }
}

.condition-lines {
  height: 100px;
  position: relative;
  margin-top: -20px;
  margin-bottom: 20px;

  svg {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 50;
  }
}

svg {
  fill: #5f667e;

  path {
    fill: none;
    stroke: #9298ad;
  }
}

.plumbing {
  display: inline-block;
  width: 158px;
  height: 70px;
}

.if-branches {
  display: flex;
  align-items: center;
  justify-content: center;

  .if-yes-branch,
  .if-no-branch {
    width: 50%;
    justify-content: center;
    display: flex;
  }

  .if-yes {
    border: 2px solid #11866f;
    color: #11866f;
  }

  .if-no {
    border: 2px solid #e40e49;
    color: #e40e49;
  }

  .plumbing {
    position: absolute;
  }
}

.blocknode-path-label {
  position: relative;
  text-align: center;
  margin: 0 0 20px 0;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 38px;
  background: #FFF;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.13);
}
</style>
